/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

const ConfirmDialog = (props) => {
  const {
    title,
    confirmDialogFlag,
    subTitle,
    oncancelDialogBtn,
    onokDialogBtn,
    okButtonText,
    okButtonOnly = false,
  } = props;

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={confirmDialogFlag !== false}
        onClose={oncancelDialogBtn}
        scroll="paper"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!okButtonOnly && (
            <Button color="secondary" onClick={oncancelDialogBtn}>
              Cancel
            </Button>
          )}
          <Button onClick={onokDialogBtn} color="primary" autoFocus>
            {okButtonText ? okButtonText : "OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default React.memo(ConfirmDialog);
